import React from 'react';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';


const NavItems = styled.ul`
  // background-color: black;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: right;
  align-items: center;
  grid-gap: 5vw;
  font-size: 1rem;
  white-space: nowrap;
  margin: 0;
  padding-right: 1.5rem;
  font-family: 'AriaText-Bold', sans-serif;
  .navItem {
      margin: 0;
      font-family: 'AriaText-Bold', sans-serif;

      a {
        color: #F5F6F7;
        text-decoration: none;
        font-family: 'AriaText-Bold', sans-serif;
      }
  }



  // .dropbtn {
  //   background-color: #04AA6D;
  //   color: black;
  //   padding: 16px;
  //   font-size: 18px;
  //   border: none;
  // }
  
  // .dropdown {
  //   position: relative;
  //   display: inline;
  // }
  
  // .dropdown-content {
  //   display: none;
  //   position: absolute;
  //   background-color: #f9f9f9;
  //   width: 100%;
  //   left: 0;
  //   box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  //   z-index: 1;
  // }

  // // .dropdown-content {
  // //     position: fixed;
  // //     top: 80px;
  // //     left: 0;
  // //     width: 100vw;
  // //     background-color: #5a5a57;
  // //     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  // //     z-index: 1;
  // //     display: none;
  // //     flex-direction: row;
  // //     align-items: stretch;
  // //     justify-content: flex-start;
  // //   }
  
  
  // .dropdown-content a {
  //   color: black;
  //   padding: 12px 16px;
  //   text-decoration: none;
  //   display: block;
  //   width: 100%; /* Make the links occupy the full width */
  //   text-align: left;
  // }
  
  // .dropdown-content a:hover {
  //   background-color: #ddd;
  // }
  
  
  // .dropdown:hover .dropdown-content {
  //   display: flex;
  // }
  
  // .dropdown:hover .dropbtn {background-color: #3e8e41;}
  
  // .navItem a.report-link {
  //   color: black !important;
  // }


  .dropdown {
    position: relative;
    display: inline-block; /* Use inline-block to wrap content */
  }

.dropdown .dropbtn {
  font-size: 16px;  
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font: inherit;
  margin: 0;
}

.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: #5a5a57;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #5a5a57;
  left: 50%; /* Adjust position to be centered */
  transform: translateX(-50%); /* Center the dropdown */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: max-content; /* Adjust width based on content */
}

.dropdown-content .header .report-link {
  margin-top: 10px;
  text-align: center;
  display: block;
}

// .dropdown-content .header {
//   padding: 0; /* Adjust padding to remove it */
//   margin: 0; /* Also consider setting margin to 0 if necessary */
//   color: white;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start; /* Align links to the left */
// }

// .dropdown-content .header {
//   background: #5a5a57;
//   padding: 0; /* Adjust padding to remove it */
//   margin: 0; /* Also consider setting margin to 0 if necessary */
//   color: white;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }

.dropdown-content .header {
  padding: 0; /* Adjust padding to remove it */
  margin: 0; /* Also consider setting margin to 0 if necessary */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left:20px;
  padding-right:20px;
}

.dropdown:hover .dropdown-content {
  display: block;
}









// @media (max-width: 2000px) {
//   .dropdown-content .header .report-link {
//     margin-top: 10px;
//     // margin-left: 1500px;
//     margin-left: 75%;
//   }
// }

// @media (max-width: 1900px) {
//   .dropdown-content .header .report-link {
//     margin-top: 10px;
//     margin-left: 74%;
//   }
// }

// @media (max-width: 1800px) {
//   .dropdown-content .header .report-link {
//     margin-top: 10px;
//     margin-left: 73%;
//   }
// }

// @media (max-width: 1700px) {
//   .dropdown-content .header .report-link {
//     margin-top: 10px;
//     margin-left: 72.5%;
//   }
// }

// @media (max-width: 1600px) {
//   .dropdown-content .header .report-link {
//     margin-top: 10px;
//     margin-left: 71.5%;
//   }
// }

// @media (max-width: 1500px) {
//   .dropdown-content .header .report-link {
//     margin-top: 10px;
//     margin-left: 70.5%;
//   }
// }

@media (max-width: 1500px) {
  .navItem {
      font-size: 16px;
  }
  grid-gap: 3vw;
}

@media (max-width: 1170px) {
  .navItem {
      font-size: 12px;
  }
  grid-gap: 2.5vw;
}

  @media (max-width: 1169px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    font-size: 1rem;
    white-space: nowrap;
    margin: 0;
    padding-right: 0.5rem;

    .dropbtn {
      font-size: 1rem;
    }
  }

  @media (max-width: 1091px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    font-size: 0.9rem;
    white-space: nowrap;
    margin: 0;
    padding-right: 0.5rem;

    .dropbtn {
      font-size: 0.9rem;
    }
  }

  @media (max-width: 1025px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    font-size: 0.8rem;
    white-space: nowrap;
    margin: 0;
    padding-right: 0.5rem;

    .dropbtn {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 970px) {
    .navItem {
        font-size: 10px;
    }
    grid-gap: 3vw;
  }

  @media (max-width: 946px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    font-size: 0.8rem;
    white-space: nowrap;
    margin: 0;
    padding-right: 0.5rem;
  }

  @media (max-width: 885px) {
    .navItem {
        font-size: 11px;
    }
    grid-gap: 2.5vw;
  }

  @media (max-width: 899px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    font-size: 0.7rem;
    white-space: nowrap;
    margin: 0;
    padding-right: 0.5rem;

    .dropbtn {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 851px) {
    .navItem {
        font-size: 9px;
    }
    grid-gap: 2vw;
  }

  @media (max-width: 842px) {
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    justify-content: right;
    align-items: center;
    font-size: 0.6rem;
    white-space: nowrap;
    margin: 0;
    padding-right: 0.5rem;

    .dropbtn {
      font-size: 0.6rem;
    }
  }

  @media (max-width: 768px) {
    li {
        padding: 30px 30px;
        font-family: 'AriaText-Bold', sans-serif;
    }
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background-color: #5a5a57;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 80px;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    font-family: 'AriaText-Bold', sans-serif;

    .navItem {
      color: #F5F6F7;
      font-family: 'AriaText-Bold', sans-serif;
      font-size: 12px;
    }
  }

  /* The animation for the underline on hover */
.navItem a {
  text-decoration: none;
  position: relative;
}

.navItem a::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #F5F6F7;
  transition: width 0.3s ease;
}

.navItem a:hover::before {
  width: 100%;
}

/* The animation for changing the underline color on hover */
.navItem a {
  position: relative;
  text-decoration: none;
  color: #F5F6F7;
  overflow: hidden;
  transition: color 0.3s ease;
  border-bottom: 2px solid transparent;
}

.navItem a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #fbd5c5;
  transition: width 0.5s ease;
}

.navItem a:hover {
  color: #fbd5c5;
}

.navItem a:hover::after {
  width: 100%;
}

/* Gradient color change for the text on hover */
.navItem a {
  background-image: linear-gradient(to right, #fbd5c5, #fbd5c5 50%, #F5F6F7 50%);
  background-size: 200% 100%;
  background-position: -100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.navItem a:hover {
  background-position: 0;
}

`;

// This declares a functional component named RightNav that takes in a prop named open.
const RightNav = ({ open }) => {
  const data = useStaticQuery(graphql`
  query MyQuery11 {
    allContentfulNavigationMenu {
      edges {
        node {
          navigationLink {
            pageLink
            pageName
          }
        }
      }
    }
  }
  `);

  // const item1 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[0];
  const item2 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[1];
  const item3 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[2];
  const item4 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[3];
  const item5 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[4];
  const item6 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[5];
  const item7 = data.allContentfulNavigationMenu.edges[0].node.navigationLink[6];

  return (
    <NavItems open={open}>
      <li className="navItem"><Link to="/">HOME</Link></li>
      {/* <li className="navItem"><Link to="/about/">{item2.pageName}</Link></li> */}
      <li className="navItem dropdown">
        <a className="navItem">ABOUT <i class="fa-solid fa-caret-down"></i></a>
        <div className="dropdown-content">
          <div className="header">
            <Link to="/about" className="report-link">The Fresh Kid</Link>
            <Link to="/my-heritage" className="report-link">Heritage</Link>
            <Link to="/press-releases" className="report-link">Press</Link>
          </div>
        </div>
      </li>
      <li className="navItem dropdown">
        <a className="navItem">SERVICES <i class="fa-solid fa-caret-down"></i></a>
        <div className="dropdown-content">
          <div className="header">
            <Link to="/luxury-customer-sentimentanalysis" className="report-link">Luxury Customer Sentiment Anlaysis</Link>
            <Link to="/luxury-keynotespeaker" className="report-link">Public Speaking</Link>
          </div>
        </div>
      </li>
      <li className="navItem"><Link to="/nuheritage/">{item3.pageName}</Link></li>
      <li className="navItem dropdown">
        <a className="navItem">REPORTS <i class="fa-solid fa-caret-down"></i></a>
        <div className="dropdown-content">
          <div className="header">
            <Link to="/luxury-circulareconomy" className="report-link">Fashion Tech</Link>
            <Link to="/luxury-diversity-and-inclusion" className="report-link">Luxury Diversity and Inclusion</Link>
          </div>
        </div>
      </li>
      <li className="navItem"><Link to="/luxury-thoughleadership/">{item5.pageName}</Link></li>
      {/* <li className="navItem"><Link to="/my-heritage/">HERITAGE</Link></li> */}
    </NavItems>
      //   <NavItems open={open}>
      //   <li className="navItem"><Link to="/">HOME</Link></li>
      //   <li className="navItem"><Link to="/about/">{item2.pageName}</Link></li>
      //   <li className="navItem dropdown">
      //     <a className="dropbtn">SERVICES <i class="fa-solid fa-caret-down"></i></a>
      //     <div className="dropdown-content">
      //       <div className="header">
      //         <Link to="/luxury-customer-sentimentanalysis" className="report-link">Luxury Customer Sentiment Anlaysis</Link>
      //         <Link to="/luxury-keynotespeaker" className="report-link">Public Speaking</Link>
      //       </div>
      //     </div>
      //   </li>
      //   <li className="navItem"><Link to="/nuheritage/">{item3.pageName}</Link></li>
      //   <li className="navItem dropdown">
      //     <a className="dropbtn">REPORTS <i class="fa-solid fa-caret-down"></i></a>
      //     <div className="dropdown-content">
      //       <div className="header">
      //         <Link to="/luxury-circulareconomy" className="report-link">Fashion Tech</Link>
      //         <Link to="/luxuryDiversityAndInclusion" className="report-link">Luxury Diversity and Inclusion</Link>
      //       </div>
      //     </div>
      //   </li>
      //   <li className="navItem"><Link to="/luxury-thoughleadership/">{item5.pageName}</Link></li>
      //   <li className="navItem"><Link to="/my-heritage/">HERITAGE</Link></li>
      // </NavItems>
  )
  
}

export default RightNav