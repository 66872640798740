import React, { useState, useEffect } from "react";
import "./scss/Footer.scss"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";

export default function Footer() {
  const data = useStaticQuery(graphql`
  query MyQuery18 {
    allContentfulFooter {
      edges {
        node {
          title
          valuePropositionText1
          valuePropositionText2
          valuePropositionText3
          footerText
          socialMediaIcons {
            youtubeIcon {
              altText
              image {
                file {
                  url
                }
              }
            }
            twitterIcon {
              image {
                file {
                  url
                }
              }
              altText
            }
            linkedInIcon {
              image {
                file {
                  url
                }
              }
              altText
            }
            facebookIcon {
              image {
                file {
                  url
                }
              }
              altText
            }
          }
          partnerLogos {
            idmLogo {
              image {
                file {
                  url
                }
              }
              altText
            }
            eConsultancyLogo {
              image {
                file {
                  url
                }
              }
              altText
            }
            bigCommerceLogo {
              image {
                file {
                  url
                }
              }
              altText
            }
          }
          imageBackground {
            image {
              file {
                url
              }
            }
            altText
          }
          footerNavigationItems {
            menuItemsText
          }
          footerImage {
            image {
              file {
                url
              }
            }
            altText
          }
          companyMarque {
            image {
              file {
                url
              }
            }
            altText
          }
        }
      }
    }
  }
  
  `)

const [showScrollButton, setShowScrollButton] = useState(false);
const [isOpen, setIsOpen] = useState(false); 
const [isOpen2, setIsOpen2] = useState(false); 
const [isOpen3, setIsOpen3] = useState(false);
const [footerExpanded, setFooterExpanded] = useState(false);


const handleScroll = () => {
  if (typeof window !== "undefined") {
    if (window.scrollY > 20) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  }
};

const scrollToTop = () => {
  if (typeof window !== "undefined") {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};

const toggleDropdown = () => {
  setIsOpen(!isOpen);
  setFooterExpanded(!footerExpanded);
};

const toggleDropdown2 = () => {
  setIsOpen2(!isOpen2);
  setFooterExpanded(!footerExpanded);
};

const toggleDropdown3 = () => {
  setIsOpen3(!isOpen3);
  setFooterExpanded(!footerExpanded);
};

useEffect(() => {
  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

  const menuItemHome = 0;
  const menuItem1 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemHome];

  const menuItemAbout = 1;
  const menuItem2 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemAbout];

  const menuItemNuHeritage = 2;
  const menuItem3 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemNuHeritage];

  const menuItemFashionTech = 3;
  const menuItem4 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemFashionTech];

  const menuItemPrivPol = 4;
  const menuItem5 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemPrivPol];

  const menuItemSiteMap = 5;
  const menuItem6 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemSiteMap];

  const menuItemBlog = 6;
  const menuItem7 = data.allContentfulFooter.edges[0].node.footerNavigationItems[menuItemBlog];

  return (
    <>
      <Helmet>
        <h2>Make Data Driven Investment and business decisions</h2>
      </Helmet>
      <footer className={`footer ${footerExpanded ? 'expanded' : ''}`}>
        <div className="footerHeader">
            {/* <div className="footerHeaderProposition">
              <ul>
                <li>
                  <a>
                    <h2>
                    {data.allContentfulFooter.edges[0].node.valuePropositionText1}
                    </h2>
                  </a>
                </li>
                <li>
                  <a>
                    <h2>
                    {data.allContentfulFooter.edges[0].node.valuePropositionText2}
                    </h2>
                  </a>
                </li>
                <li>
                  <a>
                    <h2>
                    {data.allContentfulFooter.edges[0].node.valuePropositionText3}
                    </h2>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        <div className="footerMain">
          <div className="footerMainBody">
            <div className="footerMainBodyLogos">
            <div className="footerMainBodyLinks">
            <h2 onClick={toggleDropdown}>
              Discover {isOpen ? '-' : '+'}
            </h2>
            <ul className={isOpen ? 'footerDropdownOpen' : 'footerDropdown'}>
              <br></br>
              <li className="footerLinkedListItem">
                  <Link to="/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Home</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="/about/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>The Fresh Kid</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="/my-heritage/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Heritage</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="/my-heritage/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Fashion Tech</span>
                  </Link>
              </li>
              {/* <li className="footerLinkedListItem">
                  <Link to="/pressreleases/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Press</span>
                  </Link>
              </li> */}
              <li className="footerLinkedListItem">
                  <Link to="/nuheritage/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>{menuItem3.menuItemsText}</span>
                  </Link>
              </li>
              
              {/* <li className="footerLinkedListItem">
                  <Link to="/luxury-circulareconomy/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Fashion Tech</span>
                  </Link>
              </li> */}
              {/* <li className="footerLinkedListItem">
                  <Link to="/luxuryDiversityAndInclusion/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Luxury Diversity & Inclusion</span>
                  </Link>
              </li> */}
              <li className="footerLinkedListItem">
                  <Link to="/luxury-thoughleadership/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Luxe</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="/press-releases/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Press</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="/sitemap/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Site Map</span>
                  </Link>
              </li>
            </ul>
          </div>


            {/* <div className="footerMainBodyLinks">
            <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                <h2>{menuItem1.menuItemsText}</h2>
            </Link>
              <style>{`
                    footerMainBodyLinks:visited {
                      color: white;
                    }
                  `}
              </style>
              <div className="footerLinkTitleUnderline">
                <div className="underline1" />
              </div>
              <ul>
                <li className="footerLinkedListItem">
                  <Link to="/about/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>{menuItem2.menuItemsText}</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
                <li className="footerLinkedListItem">
                  <Link to="/nuheritage/" style={{textDecoration: 'none', color: 'white' }}>
                    <span >{menuItem3.menuItemsText}</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
                <li className="footerLinkedListItem">
                  <Link to="/luxury-circulareconomy/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Reports</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
                <li className="footerLinkedListItem">
                  <Link to="/luxury-thoughleadership/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Luxe Blog</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
                <li className="footerLinkedListItem">
                  <Link to="/sitemap/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>{menuItem7.menuItemsText}</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
              </ul>
            </div>
 */}









              {/* <div className="footerCompanyLogo">
                <img
                className={"footerCompanyLogoMarque"}
                alt={``}
                key={``}
                src={data.allContentfulFooter.edges[0].node.companyMarque.image.file.url}
                />
              </div> */}
              {/* <div className="footerCompanyLogoText">
                  <p className="footerTextLeft">Our technology partnership and certifications</p>
                </div> */}
              {/* <div className="footerPartnerLogos">
                <div className="footerPartnerLogosRow1">
                  <img
                    className={"footerBigCommerceLogo"}
                    alt={``}
                    key={``}
                    src={data.allContentfulFooter.edges[0].node.partnerLogos.bigCommerceLogo.image.file.url}
                  />
                </div>
                <div className="footerPartnerLogosRow2">
                  <img
                    className={"footerEConsultancyLogo"}
                    alt={``}
                    key={``}
                    src={data.allContentfulFooter.edges[0].node.partnerLogos.eConsultancyLogo.image.file.url}
                  />

                  <img
                    className={"footerIDMLogo"}
                    alt={``}
                    key={``}
                    src={data.allContentfulFooter.edges[0].node.partnerLogos.idmLogo.image.file.url}
                  />
                </div>
              </div> */}
            </div>
            <div className="footerMainBodyLinks">
            <h2 onClick={toggleDropdown2}>
              Make an Enquiry {isOpen2 ? '-' : '+'}
            </h2>
            <ul className={isOpen2 ? 'footerDropdownOpen' : 'footerDropdown'}>
              <br></br>
              <li className="footerLinkedListItem">
                  <Link to="/contactme/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Contact Us</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <a href="mailto:hey@thefreshkid.com" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Email</span>
                  </a>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="https://www.linkedin.com/in/yemi-l-4ab0527/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Linkedin</span>
                  </Link>
              </li>
              <li className="footerLinkedListItem">
                  <Link to="/newsletter-signup/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>ŁUXE Newsletter</span>
                  </Link>
              </li>
            </ul>
          </div>













            {/* <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
                <h2>Make an Enquiry</h2>
            </Link>
              <style>{`
                    footerMainBodyLinks:visited {
                      color: white;
                    }
                  `}
              </style>
              <div className="footerLinkTitleUnderline">
                <div className="underline1" />
              </div>
              <ul>
                <li className="footerLinkedListItem">
                  <Link to="/about/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Contact Form</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
                <li className="footerLinkedListItem">
                  <Link to="/nuheritage/" style={{textDecoration: 'none', color: 'white' }}>
                    <span >Email</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li>
                <li className="footerLinkedListItem">
                  <Link to="/luxury-circulareconomy/" style={{textDecoration: 'none', color: 'white' }}>
                    <span>Linkedin</span>
                  </Link>
                  <style>{`
                    footerLinkedListItem:visited {
                      color: white;
                    }
                  `}
                  </style>
                </li> */}


              {/* </ul> */}
            {/* </div> */}
            <div className="footerMainBodyLinks">
            </div>
            <div className="footerMainBodySocial">
              {/* <div className="footerSocialMediaItems">
                <Link to="https://www.linkedin.com/in/yemi-l-4ab0527/">
                  <img
                    className={"footerSocialIcon"}
                    alt={``}
                    key={``}
                    src={data.allContentfulFooter.edges[0].node.socialMediaIcons.linkedInIcon.image.file.url}
                  />
                </Link>
              </div> */}
              <div className="footerPhoto">
                {/* <a href="mailto:hey@thefreshkid.com">
                  <div className="footerImageContainer">
                    <img
                      className={"footerImage"}
                      alt={data.allContentfulFooter.edges[0].node.footerImage.altText}
                      key={``}
                      src={data.allContentfulFooter.edges[0].node.footerImage.image.file.url}
                    />
                  </div>
                </a> */}




          <div className="footerMainBodyLinks">
            <div className="footersText" onClick={toggleDropdown3}>
            <strong>Get an Insiders Opinion {isOpen3 ? '-' : '+'}</strong>
            </div>
            <ul className={isOpen3 ? 'footerDropdownOpen' : 'footerDropdown'}>
              <br></br>
              <li className="footerLinkedListItemlux">
                  <Link to="/luxury-thoughleadership/" style={{textDecoration: 'none', color: 'white' }}>
                    <span><strong>ŁUXE</strong></span>
                  </Link>
              </li>
            </ul>
          </div>






                
                {/* <div className="footersText">
                  <strong>Get an Insiders Opinion</strong>
                </div>
              </div>
              <div className="footerLuxe">
                <Link to="/luxury-thoughleadership">

                  <strong>ŁUXE</strong>
                </Link>
                <style>{`
                    footerLuxe:visited {
                      color: white;
                    }
                  `}
                </style> */}
              </div>

              <button
                id="scrollToTopBtn"
                className="scrollToTopBtn"
                style={{
                  display: showScrollButton ? "block" : "none",
                }}
                onClick={scrollToTop}
              >
                ↑
              </button>

              <div className="footerRightText">
                <div className="footerCalltoActionR">
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* To be implemented if a copyright text is needed */}
        <div className="footerBottom"></div>  
      </footer> 
      </>
  )
}